export const theme = {
  colors: {
    primary: '#191e34',
    secondary: '#6C757D',
    background: '#fff',
    text: '#333',
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
};
