import React, {useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
//import "aos/dist/aos.css";
//import "glightbox/dist/css/glightbox.min.css";
//import "swiper/swiper-bundle.min.css";
import "../../styles/main.css";

//import "bootstrap/dist/js/bootstrap.bundle.min.js";
//import "aos/dist/aos.js";
//import "glightbox/dist/js/glightbox.min.js";
//import "swiper/swiper-bundle.min.js";
//import "../../styles/main.js";
import Img from "gatsby-image";
import heroImage from '../../assets/img/header-bg.jpg'; 

const Presentation = () => {
  return (
    <div style={{ marginBottom: "250px" }}>
        {/* Hero Section */}
        <section id="hero" class="hero section dark-background">
        <img src={heroImage} data-aos="fade-in" class="aos-init aos-animate" />
          <div class="container">
            <div class="row">
              <div class="col-lg-10">
                <h2 data-aos="fade-up" data-aos-delay="100">Snap™AI/X</h2>
                <p data-aos="fade-up" data-aos-delay="200">At Fulcrum GT, we believe that the true value of AI lies not just in its capabilities but in how it transforms the user experience. AI should streamline, simplify, and empower, creating intuitive processes that enhance efficiency across every touchpoint.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Values */}
        <section id="clients" class="clients section mt-5 mb-5" style={{ backgrounColor:"#181e36" }}>
          <div class="container" data-aos="fade-up">
            <div class="row gy-4 text-center">
              <h2 class="text-uppercase" data-aos="fade-up" data-aos-delay="100">Value Oriented AI Enabled Solutions & Experience</h2>
            </div>
          </div>
        </section>

        {/* Snap AI */}
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Snap™AI/X</h2>
            <p>What Stands Out</p>
          </div>

          <div class="container">
            <div class="row gy-4 g-0">

              {/* left col */}
              <div class="col-lg-6 " data-aos="fade-up" data-aos-delay="100">
                <div class="service-item d-flex">
                    <div class="icon flex-shrink-0"><i class="bi bi-briefcase"></i></div>
                    <div>
                      <h4 class="title"><a href="services-details.html" class="stretched-link">Seamless workflow integration</a></h4>
                      <p class="description"></p>
                    </div>
                </div>
              </div>
              <div class="col-lg-6 " data-aos="fade-up" data-aos-delay="200">
                <div class="service-item d-flex">
                  <div class="icon flex-shrink-0"><i class="bi bi-card-checklist"></i></div>
                  <div>
                    <h4 class="title"><a href="services-details.html" class="stretched-link">AI-Powered Decision Support</a></h4>
                    <p class="description"></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 " data-aos="fade-up" data-aos-delay="300">
                <div class="service-item d-flex">
                  <div class="icon flex-shrink-0"><i class="bi bi-bar-chart"></i></div>
                  <div>
                    <h4 class="title"><a href="services-details.html" class="stretched-link">Continuous learning opportunities</a></h4>
                    <p class="description"></p>
                  </div>
                </div>
              </div>

              {/* right col */}
              <div class="col-lg-6 " data-aos="fade-up" data-aos-delay="400">
                <div class="service-item d-flex">
                  <div class="icon flex-shrink-0"><i class="bi bi-binoculars"></i></div>
                  <div>
                    <h4 class="title"><a href="services-details.html" class="stretched-link">Optimised to enhance user experience</a></h4>
                    <p class="description"></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 " data-aos="fade-up" data-aos-delay="500">
                <div class="service-item d-flex">
                  <div class="icon flex-shrink-0"><i class="bi bi-brightness-high"></i></div>
                  <div>
                    <h4 class="title"><a href="services-details.html" class="stretched-link">Comprehensive Data Handling</a></h4>
                    <p class="description"></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 " data-aos="fade-up" data-aos-delay="600">
                <div class="service-item d-flex">
                  <div class="icon flex-shrink-0"><i class="bi bi-calendar4-week"></i></div>
                  <div>
                    <h4 class="title"><a href="services-details.html" class="stretched-link">Built on Strategic Partnerships</a></h4>
                    <p class="description"></p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* Solution capabilities */}
        <section id="about" class="about section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-xl-center gy-5 g-0">
              <div class="col-xl-5 content">
                <h2>Solution Capabilities</h2>
                <p>Continuously evolving AI solutions to address the unique demands of modern legal practices</p>
              </div>
            </div>
            <div class="row align-items-xl-center gy-5 g-0 mt-3 mb-5">
              <div class="col-md-12">
                <div class="row gy-4 icon-boxes g-0">
                  <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
                    <div class="icon-box">
                      <i class="bi bi-buildings"></i>
                      <h3>Intelligent Experience</h3>
                      <p>With Snap™ AI/X, we focus on bringing a seamless, direct experience to life—whether that’s through intelligent automation that eliminates repetitive tasks, chatbots that offer instant support, or workflows that reduce clicks and improve navigation. This isn't just about technology; it’s about fundamentally reshaping how work gets done so teams can focus on what truly matters. Snap™ AI/X combines powerful AI and machine learning tools with a user-centric design, creating a solution that doesn't just keep up with business needs but drives them forward. </p>
                    </div>
                  </div>
                  <div class="col-md-5" data-aos="fade-up" data-aos-delay="300" style={{ position:"relative", left:"25px" }}>
                    <div class="icon-box">
                      <i class="bi bi-clipboard-pulse"></i>
                      <h3>AI-Powered Performance</h3>
                      <p>Streamline Processes and Enhance Decision-Making <br/>Snap™ AI/X combines best-in-class practices with powerful AI and machine learning tools. Whether through automating manual tasks, enhancing cash applications, or improving narrative quality, Snap™ lets firms focus on high-value work instead of.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row gy-4 icon-boxes g-0">
                  <div class="col-md-6" data-aos="fade-up" data-aos-delay="400">
                    <div class="icon-box">
                      <i class="bi bi-command"></i>
                      <h3>Innovation Across the Legal Lifecycle</h3>
                      <p>Our AI tools integrate seamlessly into Snap™, revolutionizing processes across areas like cash management and invoice processing. With machine learning-based insights, automated payment matching, and invoice validation, Snap™ AI/X takes efficiency to new heights—no more manual entry or endless approval delays. Simply set it, and let Snap™ take care of the rest. </p>
                    </div>
                  </div>
                  <div id="faq" class="faq col-md-6" data-aos="fade-up" data-aos-delay="400" style={{ position:"relative", left:"25px" }}>
                    <h3><span>Benefits</span></h3>

                    <div class="col-lg-8 g-0 mt-3 mb-5" data-aos="fade-up" data-aos-delay="200">

                      <div class="faq-container">
                        <div class="faq-item">
                          <h3><span class="num">1.</span> <span>Reduced manual workload</span></h3>
                        </div>

                        <div class="faq-item">
                          <h3><span class="num">2.</span> <span>Enhanced decision-making insights</span></h3>
                        </div>

                        <div class="faq-item">
                          <h3><span class="num">3.</span> <span>Streamlined, intuitive workflows</span></h3>
                        </div>

                        <div class="faq-item">
                          <h3><span class="num">4.</span> <span>Real-time data access</span></h3>
                        </div>

                        <div class="faq-item">
                          <h3><span class="num">5.</span> <span>Improved operational efficiency</span></h3>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  );
};

export default Presentation;
