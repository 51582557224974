import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import Presentation from "../Presentation";
import { theme } from "../../styles/theme.js";
import Header from '../Header'; 
import Footer from '../Footer'; 

const SpecialLayout = ({ children }) => {
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0); 

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    // Set initial header height
    updateHeaderHeight();

    // Update header height on window resize
    window.addEventListener('resize', updateHeaderHeight);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Header ref={headerRef} />
      <PresentationWrapper topOffset={130}>
        <Presentation />
      </PresentationWrapper>
      <Content padTop={headerHeight}>
        {children}
      </Content>
      <Footer />
    </ThemeProvider>
  );
};

export default SpecialLayout;

const Content = styled.main`
  position: relative;
  top: ${(props) => props.padTop}px;
  overflow-x: hidden;
`;

const PresentationWrapper = styled.div`
  position: relative;
  top: ${(props) => props.topOffset}px;
`;

SpecialLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
